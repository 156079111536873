/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";

html,
body {
  margin: 0;
  font-family: "Roboto,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif";
  height:100vh;
}

sup {
  vertical-align: text-top;
}

#component-container{
  width: 90vw;
  min-width: 1000px;
  height: fit-content;
  margin: 1vh auto;
  padding: 15px;
  border-radius: 5px;
  background-color: #b9b9b92f;
  display: flex;
  flex-direction: column;
  background-color: rgba(124, 179, 66, 0.65);
}
